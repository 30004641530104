@import url(https://fonts.googleapis.com/css?family=MULISH+regular);
@import url(https://fonts.googleapis.com/css?family=MONTSERRAT+semibold);
@import url(https://fonts.googleapis.com/css?family=LORA+regular);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body,
html {
  height: 100%;
  margin: 0;
  background-color: #ffffff !important;
  font-family: "arial ";
}
#collasible-nav-dropdown > a,
a.dropdown-item {
  font-family: "MONTSERRAT";
  font-size: 1em;
}
.main-content {
  background-color: #fff;
}
.navbar {
  font-family: "MONTSERRAT semibold";
  background-color: #000 !important;
}

.card-header {
  font-family: "MULISH";
  font-size: 1.5em;
}
h2.grid-categorie {
  font-family: "MULISH";
  font-size: 1.2em;
  background-color: #000;
  color: #efefef;
  padding: 10px;
  margin-top: 10px;
}
p {
}

h1.detail-project-title {
  font-size: "45px";
}
h2.detail-project-subtitle {
  font-size: "24px";
  color: "#565656";
}
p.detail-project-text {
  font-size: "24px";
}

p.detail-project-materiaux {
  font-size: "14px";
}
p.detail-project-taille {
  font-size: "14px";
}

